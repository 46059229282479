<style lang="scss">
  @import "../../../../../assets/css/_colors";
  @import "../../../../../assets/css/design-tokens/_colors";

  .dropdownContainer {
    position: relative;

    .dropdownMenu {
      max-height: 70vh;
      border-radius: 0.5rem;
      box-shadow: 0 3px 1.5rem 0 rgba(black, 0.35);
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      overflow-y: auto;
      padding: 0.5rem;
      position: fixed;
      z-index: 99;

      &.isEmpty {
        align-items: center;
        gap: 1rem;
        justify-content: center;

        .caught-up-whale {
          color: #cacacc;
          font-size: 1.5rem;
        }

        p {
          color: #677084;
        }
      }

      &.primary {
        background-color: $sw-blue;

        &.isImpersonating {
          background-color: $red;
        }
      }

      &.muted {
        background-color: white;
        padding: 0;
      }

      .divider {
        border-top: 1px solid rgba(white, 0.3);
        margin: 0.5rem 0;
      }
    }
  }
</style>

<script>
  import { onMount } from "@lifecycle";
  import { onDestroy, tick } from "svelte";
  import { cookieValue } from "../../../../../assets/js/src/general/utils";
  import DropdownMenuItem from "./DropdownMenuItem.svelte";

  export let items;
  export let type = "";
  export let targetElement;
  export let offsetElementId = "";
  export let offsetElement = null;
  export let width = "12.5rem";
  export let height = "auto";

  let dropdownMenu;
  let isEmpty = items?.length === 0;
  let listeners = { cleanup: null };

  $: isImpersonating = cookieValue("impersonating") === "true";

  const calculateHorizontalPosition = () => {
    const element = offsetElementId
      ? dropdownMenu.getRootNode().getElementById(offsetElementId)
      : offsetElement || targetElement;

    const offsetRect = element.getBoundingClientRect();
    const dropdownRect = dropdownMenu.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const offset = 6;

    let left = offsetRect.right + offset;
    if (left + dropdownRect.width > windowWidth) {
      left = offsetRect.left - dropdownRect.width - offset;
    }
    return left;
  };

  const calculateVerticalPosition = () => {
    const targetRect = targetElement.getBoundingClientRect();
    const dropdownRect = dropdownMenu.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    let { top } = targetRect;
    if (top + dropdownRect.height > windowHeight) {
      top = targetRect.top + targetRect.height - dropdownRect.height;
      if (top + dropdownRect.height > windowHeight) {
        top = targetRect.top - dropdownRect.height;
      }
    }
    return top;
  };

  const calculatePosition = () => {
    if (!targetElement || !dropdownMenu) return;

    dropdownMenu.style.left = `${calculateHorizontalPosition()}px`;
    dropdownMenu.style.top = `${calculateVerticalPosition()}px`;
  };

  onMount(() => {
    tick().then(calculatePosition);
    document.addEventListener("scroll", calculatePosition, true);
    document.addEventListener("resize", calculatePosition, true);
    listeners.cleanup = () => {
      document.removeEventListener("scroll", calculatePosition, true);
      document.removeEventListener("resize", calculatePosition, true);
    };
  });

  onDestroy(() => {
    if (listeners.cleanup) {
      listeners.cleanup();
    }
  });
</script>

<div class="dropdownContainer">
  <ul
    bind:this={dropdownMenu}
    class="dropdownMenu {type}"
    class:isEmpty
    class:isImpersonating
    style="width: {width}; height: {height};"
  >
    {#if !isEmpty}
      {#each items as item}
        {#if item.type === "divider"}
          <li class="divider"></li>
        {:else}
          <DropdownMenuItem {item} {type} offsetElement={dropdownMenu} />
        {/if}
      {/each}
    {:else}
      <i class="caught-up-whale fa-solid fa-whale"></i>
      <p>You're all caught up!</p>
    {/if}
  </ul>
</div>
