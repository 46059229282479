<style lang="scss">
  $borderWidth: 4px;
  $animationTime: 0.3s;
  $border-color-default: rgba(255, 255, 255, 0.8);
  $border-color-fill: #46d7a1;
  $size: 2.75rem;
  $howManySteps: 100;

  #progress {
    width: $size;
    height: $size;
    align-items: center;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;

    &:hover {
      $hover-color: rgba(white, 0.15);

      background-color: $hover-color;
      outline: 4px solid $hover-color;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
    }

    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }

    #progressLeft,
    #progressRight {
      #progressBar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: $borderWidth;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $border-color-fill;
      }
    }

    #progressLeft {
      left: 0;

      #progressBar {
        left: 100%;
        border-top-right-radius: calc($size / 2);
        border-bottom-right-radius: calc($size / 2);
        border-left: 0;
        transform-origin: center left;
      }
    }

    #progressRight {
      right: 0;

      #progressBar {
        left: -100%;
        border-top-left-radius: calc($size / 2);
        border-bottom-left-radius: calc($size / 2);
        border-right: 0;
        transform-origin: center right;
      }
    }

    #progressValue {
      font-size: 0.8rem;
      color: white;
      position: relative;

      > span {
        font-size: 12px;
        text-transform: uppercase;
        animation: fadeIn 0.3s ease-in-out forwards;
      }
    }
  }

  @for $i from 1 through $howManySteps {
    $stepName: ($i * calc(100 / $howManySteps));
    $halfSteps: calc($howManySteps / 2);

    #progress[data-percentage="#{$stepName}"] {
      #progressRight #progressBar {
        animation: loading-#{min($i, $halfSteps)}
          $animationTime
          linear
          forwards;
      }
      #progressLeft #progressBar {
        @if $i > $halfSteps {
          animation: loading-#{$i -
            $halfSteps}
            $animationTime
            linear
            forwards
            $animationTime;
        } @else {
          animation: none;
        }
      }
    }
  }

  @for $i from 1 through calc($howManySteps / 2) {
    $degrees: calc(180 / calc($howManySteps / 2) * $i);
    @keyframes loading-#{$i} {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(#{$degrees}deg);
      }
    }
  }
</style>

<script>
  import { trainingHubTaskStore } from "../../../../api-stores/training-hub-store";
  import SkeletonLoader from "../Components/SkeletonLoader.svelte";

  trainingHubTaskStore.once();
  const { meta } = trainingHubTaskStore;
  $: completedTasks = $trainingHubTaskStore.totalProgress.completedTasks || 0;
  $: totalTasks = $trainingHubTaskStore.totalProgress.totalTasks || 0;
  $: completedPercent =
    totalTasks === 0 ? 0 : Math.round((completedTasks / totalTasks) * 100);
</script>

<div id="progress" data-percentage={completedPercent}>
  <span id="progressLeft">
    <span id="progressBar"></span>
  </span>
  <span id="progressRight">
    <span id="progressBar"></span>
  </span>
  <div id="progressValue">
    {#if !$meta.firstFetchDone}
      <SkeletonLoader />
    {:else}
      <span>{completedTasks}/{totalTasks}</span>
    {/if}
  </div>
</div>
