<style lang="scss">
  #trainingHubSidebarButton {
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    outline: none;
    position: relative;
    justify-content: center;

    #trainingHubSidebarDialog {
      @keyframes zoom {
        from {
          transform: scale(0.95);
        }
        to {
          transform: scale(1);
        }
      }

      width: 23.75rem;
      animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
      background: white;
      bottom: 0;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      cursor: default;
      display: grid;
      gap: 0.5rem;
      left: 80px;
      overflow: hidden;
      padding: 0.5rem;
      position: absolute;
      text-align: left;
    }
  }
</style>

<script>
  import { userStore } from "../../../../api-stores/user-store";
  import ButtonContent from "./ButtonContent.svelte";
  import DialogContent from "./DialogContent.svelte";
  import { showDialog } from "./stores";

  $: shouldShow = $userStore?.trainingHubStatus === "inProgress";

  let hideTimer;

  const showSidebar = () => {
    clearTimeout(hideTimer);
    $showDialog = true;
  };

  const hideSidebar = () => {
    hideTimer = setTimeout(() => {
      $showDialog = false;
    }, 500);
  };

  let elButton;
  let elDialog;

  $: iconAria = $showDialog
    ? {
        "aria-controls": "trainingHubSidebarDialog",
      }
    : {};
</script>

{#if shouldShow}
  <button
    id="trainingHubSidebarButton"
    tabindex="0"
    aria-haspopup="dialog"
    aria-expanded={$showDialog}
    bind:this={elButton}
    {...iconAria}
    on:mouseenter={showSidebar}
    on:mouseleave={hideSidebar}
    class:$showDialog
  >
    <ButtonContent {$showDialog} />
    {#if $showDialog}
      <div
        id="trainingHubSidebarDialog"
        bind:this={elDialog}
        role="dialog"
        aria-labelledby="trainingHubSidebarButton"
      >
        <DialogContent />
      </div>
    {/if}
  </button>
{/if}
