import { mount } from "svelte";

export const createShadowRoot = (element, options = { mode: "open" }) =>
  element.attachShadow(options);

export const loadStylesheets = async (target, stylesheets) => {
  const loadStylesheet = (href) =>
    new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = href;
      link.onload = () => resolve();
      link.onerror = () =>
        reject(new Error(`Failed to load stylesheet: ${href}`));
      target.appendChild(link);
    });

  return Promise.all(stylesheets.map(loadStylesheet));
};

export const bindToShadowDOM = async ({
  elementId,
  component: Component,
  stylesheets = [],
  shadowOptions = { mode: "open" },
  createElement = false,
}) => {
  let element;
  if (createElement) {
    element = document.createElement("div");
    element.id = elementId;
    document.body.appendChild(element);
  } else {
    element = document.getElementById(elementId);
    if (!element) {
      throw new Error(`Element with id "${elementId}" not found`);
    }
  }

  const target = element.attachShadow(shadowOptions);

  try {
    await loadStylesheets(target, stylesheets);
    return mount(Component, { target });
  } catch (error) {
    console.error(`Failed to initialize component:`, error);
    throw error;
  }
};
