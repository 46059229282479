<style lang="scss">
  @import "../../../../../assets/css/global.scss";
  .training-body {
    padding: 0.5rem 1rem;

    h3 {
      margin-top: 0rem;
      color: $text-black;
      font-size: 1.1rem;
    }

    .next-task {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem;

      .task-details {
        display: flex;
        flex-direction: column;

        .task-title {
          font-weight: 700;
          color: $soft-black;
          font-size: 1rem;
        }

        .task-duration {
          font-size: 0.9rem;
          color: $text-gray;
        }
      }
    }
  }

  .training-footer {
    display: flex;
    text-align: center;
  }
</style>

<script>
  import ActionButton from "../Components/ActionButton.svelte";
  import BadgesWidget from "../Components/BadgesWidget.svelte";
  import TrainingHubIcon from "../Components/TrainingHubIcon.svelte";
  import { currentTask, currentTaskIndex } from "./stores";

  $: href = $currentTask ? `/training#${$currentTask.taskId}` : "/training";
  $: text = $currentTask ? "Go to next task" : "Go to training hub";
</script>

<BadgesWidget />
{#if $currentTask}
  <div class="training-body">
    <h3>Next task</h3>
    <div class="next-task">
      <TrainingHubIcon
        icon={$currentTask.icon}
        colorIndex={$currentTaskIndex}
      />
      <div class="task-details">
        <span class="task-title">{$currentTask.title}</span>
        <span class="task-duration">About {$currentTask.duration} mins</span>
      </div>
    </div>
  </div>
{/if}
<div class="training-footer">
  <ActionButton {href} class="primary" style="min-width:100%">
    {text}
  </ActionButton>
</div>
