import { derived, writable } from "svelte/store";

import { trainingHubTaskStore } from "../../../api-stores/training-hub-store.js";
import { userStore } from "../../../api-stores/user-store.js";
import { updateTask } from "../../../api/training-hub";

export const isVideoPlaying = writable(false);

export const createVideoTracker = ({
  taskId,
  taskStatus,
  videoEl,
  isPreview = false,
}) => {
  let totalPlayedTime = 0;
  let lastUpdateTime = 0;
  let lastSentTime = 0;
  let lastRequestTime = 0;
  let playing = false;

  const play = () => {
    playing = true;
    isVideoPlaying.set(playing);
  };
  const pause = () => {
    playing = false;
    isVideoPlaying.set(playing);
  };

  const timeupdate = () => {
    if (!playing) return;
    const now = Date.now();
    if (!lastUpdateTime) {
      lastUpdateTime = now;
      return;
    }
    const elapsedTime = (now - lastUpdateTime) / 1000;
    lastUpdateTime = now;

    totalPlayedTime += elapsedTime * videoEl.playbackRate;

    const roundedTime = Math.round(totalPlayedTime);

    if (roundedTime % 5 === 0 && roundedTime > lastSentTime) {
      lastSentTime = roundedTime;

      // Update task after 70% of video duration
      if (
        !isPreview &&
        taskStatus !== "completed" &&
        totalPlayedTime >= videoEl.duration * 0.7
      ) {
        // Only update task once every 15 seconds (idea is that the task should have updated to completed by then)
        if (now - lastRequestTime >= 15000) {
          updateTask({ taskId });
          lastRequestTime = now;
        }
      }
    }
  };

  videoEl.addEventListener("timeupdate", timeupdate);
  videoEl.addEventListener("play", play);
  videoEl.addEventListener("pause", pause);

  return {
    taskId,
    status: taskStatus,
    reset: () => {
      videoEl.pause();

      videoEl.removeEventListener("timeupdate", timeupdate);
      videoEl.removeEventListener("play", play);
      videoEl.removeEventListener("pause", pause);
      totalPlayedTime = 0;
      lastUpdateTime = 0;
      lastSentTime = 0;
      lastRequestTime = 0;
    },
  };
};

const scrollTaskIntoView = (taskId) => {
  setTimeout(() => {
    const taskElement = document.getElementById(taskId);
    if (taskElement)
      taskElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, 500);
};

const InteractiveTasks = () => {
  const data = {
    selectedTaskId: window.location.hash.slice(1),
    selectedTaskGroupId: "",
    tasks: [], // filtered tasks - by selected card
    selectedTaskGroup: {},
    lastUserAction: null,
  };
  const subscribers = new Set();

  let trainingHubData = {};
  const notify = () => {
    // Compute filtered tasks
    if (
      !(trainingHubData.taskGroups || [])
        .map((g) => g.taskGroupId)
        .includes(data.selectedTaskGroupId)
    ) {
      data.selectedTaskGroupId = "";
    }

    data.tasks = (trainingHubData.tasks || []).filter(
      (task) =>
        !data.selectedTaskGroupId ||
        task.taskGroupId === data.selectedTaskGroupId,
    );

    if (!data.selectedTaskId && data.lastUserAction === "setTaskGroup") {
      data.selectedTaskId =
        data.tasks.find(
          (task) => !["completed", "locked", "pending"].includes(task.status),
        )?.taskId || "";
    }

    // Choose selected progress
    data.selectedTaskGroup =
      (trainingHubData.taskGroups || []).find(
        (group) => group.taskGroupId === data.selectedTaskGroupId,
      ) || trainingHubData.totalProgress;

    subscribers.forEach((s) => s(data));

    if (data.selectedTaskId) {
      window.history.replaceState({}, "", `#${data.selectedTaskId}`);
      scrollTaskIntoView(data.selectedTaskId);
    } else {
      window.history.replaceState({}, "", window.location.href.split("#")[0]);
    }
  };

  let parentUnsubscribe = null;
  const manageParentSubscription = () => {
    if (subscribers.size > 0 && parentUnsubscribe === null) {
      parentUnsubscribe = trainingHubTaskStore.subscribe((res) => {
        if (!res || !res.tasks || res.tasks.length === 0) return;
        trainingHubData = res;

        const task = trainingHubData.tasks.find(
          (t) => t.taskId === data.selectedTaskId,
        );
        if (task) {
          const group = (trainingHubData.taskGroups || []).find(
            (g) => g.taskGroupId === task.taskGroupId,
          );
          data.selectedTaskGroupId = group ? group.taskGroupId : "";
        } else {
          const taskGroups = trainingHubData.taskGroups || [];
          data.selectedTaskId = "";
          data.selectedTaskGroupId = taskGroups[0]?.taskGroupId || "";
        }
        notify();
      });
    } else if (subscribers.size === 0 && parentUnsubscribe !== null) {
      parentUnsubscribe();
      parentUnsubscribe = null;
    }
  };

  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    manageParentSubscription();
    subscriber(data);
    return () => {
      subscribers.delete(subscriber);
      manageParentSubscription();
    };
  };

  const setTask = (taskId) => {
    data.selectedTaskId = taskId;
    if (taskId && trainingHubData.taskGroups) {
      const task = trainingHubData.tasks.find((t) => t.taskId === taskId);
      data.selectedTaskGroupId = task ? task.taskGroupId : "";
    }
    data.lastUserAction = "setTask";
    notify();
  };

  const setTaskGroup = (taskGroupId) => {
    if (!taskGroupId && trainingHubData.taskGroups) return;
    if (taskGroupId && !trainingHubData.taskGroups) return;

    if (!taskGroupId && !trainingHubData.taskGroups) {
      data.selectedTaskGroupId = "";
      notify();
      return;
    }

    const taskGroup = trainingHubData.taskGroups.find(
      (group) => group.taskGroupId === taskGroupId,
    );
    if (!taskGroup) {
      return;
    }

    if (data.selectedTaskGroupId !== taskGroupId) {
      data.selectedTaskId = "";
    }
    data.selectedTaskGroupId = taskGroupId;

    data.lastUserAction = "setTaskGroup";
    notify();
  };

  return {
    subscribe,
    setTask,
    setTaskGroup,
  };
};
export const interactiveTasks = InteractiveTasks();

/** @param {HashChangeEvent} e */
export const onHashChange = (e) => {
  const taskId = e.newURL.split("#")[1];
  if (taskId) {
    interactiveTasks.setTask(taskId);
  }
};

export const showVideoPreviewModal = writable(false);

export const firstNameStore = derived(
  [userStore],
  ([$userStore]) => ($userStore.displayName || "").split(" ")[0],
);

export const welcomeSteps = derived(firstNameStore, ($firstNameStore) => [
  {
    title: "WELCOME",
    subtitle: `Welcome to the SourceWhale Training Hub, ${$firstNameStore}!`,
    content:
      "It's great to have you on board! The training hub will guide you through setup and key tasks to help you get the most out of SourceWhale.",
    image: "/assets/img/training-hub/woman-using-sourcewhale.png",
    imageHeight: 270,
    ctaText: "Go to first task",
  },
  // TODO: Amelia said add this back in future project phase
  // {
  //   title: "REWARDS",
  //   subtitle: "Complete onboarding tasks to gain SourceWhale badges",
  //   content:
  //     "Complete tasks to level up and unlock new areas of the training hub. Earn badges as you progress through the onboarding. You'll be a SourceWhale pro in no time!",
  //   image: "/assets/img/training-hub/training-trophies.svg",
  //   imageHeight: 190,
  //   backgroundImage: "/assets/img/training-hub/confetti.svg",
  // },
  // {
  //   title: "TIPS & TRICKS",
  //   subtitle:
  //     "Unlock tips and tricks to learn about advanced SourceWhale features",
  //   content:
  //     "Dive deeper into SourceWhale with tips and tricks that reveal its most powerful features. Master these to enhance your skills and maximize your results!",
  //   image: "/assets/img/training-hub/magicwand.svg",
  //   imageHeight: 170,
  // },
  // {
  //   title: "HELPFUL RESOURCES",
  //   subtitle: "Register for live training and explore our Help Centre",
  //   content:
  //     "Want to learn more? Register for live training sessions or dive into our Help Centre for detailed resources and support. We're here to help you every step of the way!",
  //   image: "/assets/img/training-hub/man-on-phone.png",
  //   imageHeight: 300,
  //   ctaText: "Go to first task",
  // },
]);

export const completionViewerSteps = [
  {
    title: "WHAT'S NEW",
    subtitle:
      "Congratulations! You Have Completed All of Your Onboarding Tasks",
    content:
      "You've successfully completed onboarding and mastered the core features of SourceWhale. Great job! Remember, you can return to the Training Hub at any time to revisit tasks or refresh your skills.",
    image: "/assets/img/training-hub/badge.svg",
    imageHeight: 150,
    backgroundImage: "/assets/img/training-hub/confetti.svg",
  },
  {
    title: "COME BACK SOON",
    subtitle: "It's Not Goodbye! You Can Access the Training Hub at Any Time",
    content:
      "Simply click your profile image in the bottom left corner of SourceWhale and select 'Training Hub' to return anytime! Revisit tasks or access helpful guides.",
    image: "/assets/img/training-hub/access-training-hub.gif",
    imageHeight: 270,
    ctaText: "Return to Training Hub",
    showGradient: false,
  },
];
