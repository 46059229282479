<style lang="scss">
  @use "../../../../../../assets/css/design-tokens/colors";
  @use "../../../../../../assets/css/_mixins";
  @import "../../../../../../assets/css/_colors";

  .navItem {
    @include mixins.size(4.25rem);
    align-items: center;
    border-radius: 0.5rem;
    color: white;
    cursor: default;
    display: flex;
    position: relative;
    justify-content: center;

    &:not(&Submenu) {
      cursor: pointer;
    }

    &:hover,
    &.highlight {
      background-color: rgba(white, 0.1);
    }

    &.highlight {
      .icon {
        font-weight: bold;
      }
    }

    .navItemInner {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.357rem;
      position: relative;

      .badge {
        background-color: colors.$pink;
        border-radius: 2rem;
        color: white;
        font-size: 0.625rem;
        font-weight: bold;
        line-height: 15px;
        padding: 1px 4px;
        position: absolute;
        top: -0.9rem;
        right: -0.5rem;

        &.isImpersonating {
          background-color: colors.$warning;
        }

        &.new {
          right: -1.3rem;
        }
      }

      .text {
        font-size: 0.75rem;
      }
    }
  }
</style>

<script>
  import { onDestroy } from "svelte";
  import { cookieValue } from "../../../../../../assets/js/src/general/utils";
  import DropdownMenu from "../../DropdownMenu/DropdownMenu.svelte";
  import { currentPath } from "../../stores/current-path-store";

  export let navItem;

  $: isImpersonating = cookieValue("impersonating") === "true";
  $: badgeValue = navItem.badgeValue;

  let { badgeClass } = navItem;
  let hasDropdownMenu = navItem.menu;
  let displayDropdownMenu = false;
  let hideTimer;
  let targetElement;
  let highlight = $currentPath === navItem.link;

  onDestroy(() => {
    clearTimeout(hideTimer);
  });

  const showDropdownMenu = () => {
    clearTimeout(hideTimer);
    displayDropdownMenu = true;
  };

  const hideDropdownMenu = () => {
    hideTimer = setTimeout(() => {
      displayDropdownMenu = false;
    }, 150);
  };

  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      showDropdownMenu();
    }
  };
</script>

<a
  bind:this={targetElement}
  href={navItem.link}
  class="navItem"
  class:highlight
  data-testid="navItem_{navItem.id}"
  tabindex="0"
  on:mouseenter={showDropdownMenu}
  on:mouseleave={hideDropdownMenu}
  on:keydown={handleKeydown}
>
  <div class="navItemInner">
    <i class="icon {navItem.icon}"></i>
    {#if badgeValue}
      <span class="badge {badgeClass}" class:isImpersonating>{badgeValue}</span>
    {/if}
    <span class="text">{navItem.name}</span>
  </div>
  {#if hasDropdownMenu && displayDropdownMenu}
    <DropdownMenu
      items={navItem.menu}
      type="primary"
      offsetElementId="sidebar"
      {targetElement}
    />
  {/if}
</a>
