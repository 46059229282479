<style lang="scss">
  @use "../../../../../assets/css/mixins";

  .navItem {
    align-items: center;
    color: white;
    border-radius: 6px;
    display: flex;
    position: relative;
    transition: opacity 0.1s ease-in;
    justify-content: center;
    @include mixins.size(2.75rem);

    &.button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }

    &:hover,
    &.isOpen {
      background-color: rgba(white, 0.1);
    }
  }
</style>

<script>
  export let href;
  export let title = "";
  export let classList = "";
  export let onClick = () => {};
</script>

{#if href}
  <a {href} {title} class="navItem {classList}" tabindex="0">
    <slot />
  </a>
{:else}
  <button
    class="navItem button {classList}"
    {title}
    on:click|stopPropagation={onClick}
  >
    <slot />
  </button>
{/if}
